import React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout noSidebar>
    <h1>Page Not Found</h1>
    <p>
      Sorry, something went wrong and the page you are looking for does not
      exist.
    </p>
  </Layout>
);

export default NotFoundPage;
